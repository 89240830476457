import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import EmailSelectionPage from './EmailSelectionPage';
import { useEmailContext } from './EmailContext';
import HomePage from './HomePage';
import Dashboard from './Dashboard';
import ProtectedRoute from './ProtectedRoute';
import MainList from './MainList';
import Report from './Report';



const App = () => {
  const navigate = useNavigate();
  const { fetchEmailDetails } = useEmailContext();

  const handleEmailSelect = async (id) => {
    await fetchEmailDetails(id);
    navigate('/MainList');
  };

  

  return (
    
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/EmailSelectionPage" element={<ProtectedRoute><EmailSelectionPage onEmailSelect={handleEmailSelect} /></ProtectedRoute>} />
        <Route path="/Dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/MainList" element={<ProtectedRoute><MainList /></ProtectedRoute>} />
        <Route path="/Report" element={<ProtectedRoute><Report /></ProtectedRoute>} />
      </Routes>
      
  );
};

export default App;
