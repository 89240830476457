import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  //Divider,
  //Drawer,
  IconButton,
  //List,
 // ListItem,
  //ListItemIcon,
  //ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import {  Routes, Route } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
//import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
//import ContentPasteIcon from '@mui/icons-material/ContentPaste';
//import AssessmentIcon from '@mui/icons-material/Assessment';
//import MainList from './MainList';
import Report from './Report';
import HomePage from './HomePage';
import logo from './logo.png';
import ProtectedRoute from './ProtectedRoute';
import AuthButtons from './AuthButtons';
//import theme from './theme';
import NotFoundPage from './NotFoundPage';
//import UserProfile from './UserProfile';



const Dashboard = () => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* AppBar */}
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            sx={{ marginRight: '36px', ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          {/* Logo */}
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{
              height: 40,
              width: 40,
              marginRight: 2,
            }}
          />
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            What The Phish Reports
          </Typography>
          {/* Authentication Buttons and User Profile */}
          <AuthButtons />

        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}


      {/* Main Content */}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {/* Define your Routes here */}
          <Routes>
            {/* Unprotected HomePage */}
            <Route path="/" element={<HomePage />} />

            {/* Protected Email Analysis Page */}
          
            {/* Protected Company Report Page */}
            <Route
              path="/Report"
              element={
                <ProtectedRoute>
                  <Report />
                </ProtectedRoute>
              }
            />

            {/* Catch-all Route for 404 */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Container>
      </Box>
    </Box>
  );
};

export default Dashboard;
